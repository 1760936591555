// wrapper around smartid service
import router from '../routes'
import {store} from '../store/store'
import environ from 'smarttech-identity-environment'
import { createApiClient } from './api-client'
import ErrorHandler from '../utils/error-handler'
import DataValidator from 'smarttech-identity-data-validator'
import BodyUtil from '../utils/body-visibility-util'

let ID = null
let CLIENT_ID = 'softwareportal.smarttech.com'

function doFederatedLogout (accessToken) {
    let url = environ.getIDServiceUrl() + '/logout/v2' +
        '?client_id=' + CLIENT_ID +
        '&redirect_uri=' + `${window.location.protocol}//${window.location.host}/login` +
        '&access_token=' + accessToken

    window.location = url
}

function getOptionalLoginOption (loginHint, expId) {
    let result = environ.getIdLoginParamBaseConfig()
    result.identity_realm = 'softwareportal'
    if (loginHint) {
        result.login_hint = loginHint
    }
    if (expId) {
        result.exp_id = expId
    }
    return result
}

export let idAuth = {
    cachedError: null,
    initialize (id) {
        ID = id
        return new Promise((resolve, reject) => {
            ID.addLoginListener(token => {
                try {
                    if (token) {
                        store.commit('setAccessToken', token)
                    }

                    store.dispatch('WaitOnApplicationInitialization')
                        .catch(err => {
                            ErrorHandler.handleError(err)
                        })

                    let state = ID.getState()

                    if (state) {
                        state = this.decodeState(state)
                    }

                    let claimCode = this.getClaimCode(state)

                    if (claimCode) {
                        this.validateClaimCode(claimCode)
                    }
                    // else - normal login just go to products route
                    environ.clearRegionSwitchBypassPreIdp()
                    ID.getProfile(profileInfo => {
                        store.commit('ProfileInfoReceived', profileInfo)

                        if (document.location.pathname.includes('/login')) {
                            router.push('/products')
                        }
                        resolve()
                    })
                } catch (err) {
                    err.errorGroup = ErrorHandler.signinError
                    reject(err)
                }
            })

            ID.addLogoutListener(() => {
                BodyUtil.hideBody()
                let accessToken = store.getters.accessToken
                store.commit('setAccessToken', '')
                const params = this.getURLParams()
                // If we have a claim code don't redirect to login as we will try to login
                // shortly.
                if (params && params.has('code')) {
                    resolve()
                    return
                }

                if (accessToken) {
                    if (process.env.NODE_ENV !== 'production') {
                        router.go('/login')
                    } else {
                        doFederatedLogout(accessToken)
                    }
                }

                resolve()
            })

            ID.addTokenListener(token => {
                store.commit('setAccessToken', token ? token.accessToken : '')
            })

            ID.addErrorListener(error => {
                // TODO: update ID JS Lib to offer the proof that some errors are related.
                //        the cachedError codes below are not used.
                // We can get multiple errors on login failure we want to handle the first one
                // And local_storage_access_error should always be handled as it means third party
                // cookies are disabled.
                if (!this.cachedError || error.errorCode === 'local_storage_access_error') {
                    this.cachedError = error
                    this.cachedError.errorGroup = ErrorHandler.idLibraryError
                }

                error.errorGroup = ErrorHandler.idLibraryError
                reject(error)
            })

            ID.init({
                client_id: CLIENT_ID,
                redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
                region: environ.getRegionForIdServer(),
                env: environ.getEnvironment(),
                scope: [
                    'profile',
                    'profile.email',
                    'organization'
                ],
                identity_realm: 'softwareportal',
                cookie_path: '/',
                persist_state: true,
                debug: process.env.VUE_APP_ID_DEBUG,
                refreshPeriodInSeconds: 60, // refresh token every minute
                maxTokenExpiryInSeconds: 60 * 88 // get a new token if token expires in less than 88 minutes
            })
        })
    },

    getClaimCode (state) {
        let claimCode = null
        const params = this.getURLParams()

        if (state && state.claimCode) {
            claimCode = state.claimCode
        } else if (params && params.has('code')) {
            claimCode = params.get('code')
        }

        return claimCode
    },

    validateClaimCode (claimCode) {
        // call into backend to get product_list
        store.dispatch('ValidateClaimCode', {
            claimCode: claimCode
        }).catch(err => {
            ErrorHandler.handleError(err, ErrorHandler.claimValidationError)
        })
    },

    getURLParams () {
        return new URLSearchParams(location.search)
    },

    encodeState (state) {
        return window.btoa(JSON.stringify(state))
    },

    decodeState (state) {
        return JSON.parse(window.atob(state))
    },

    login (state, idpExperienceId, loginHint) {
        if (loginHint) {
            BodyUtil.hideBody()
        }
        const apiClient = createApiClient()
        if (idpExperienceId) {
            DataValidator.shouldBeBlank(state, 'state', 'while idp experience id present')
            BodyUtil.hideBody()
            apiClient.getExperience(idpExperienceId)
                .then((result) => {
                    ID.login(result.json.state, getOptionalLoginOption(loginHint, ''))
                }).catch(error => BodyUtil.handleApiErrorBeforeSignIn(error))
        } else if (state) {
            if (state.claimCode) {
                let encodedStateString = this.encodeState(state)
                BodyUtil.hideBody()
                apiClient.createExperience(encodedStateString)
                    .then(result => {
                        ID.login(encodedStateString, getOptionalLoginOption(loginHint, result.json.experienceId))
                    }).catch(error => BodyUtil.handleApiErrorBeforeSignIn(error))
            } else {
                ID.login(this.encodeState(state), getOptionalLoginOption(loginHint, ''))
            }
        } else {
            ID.login('', getOptionalLoginOption(loginHint, ''))
        }
    },

    logout () {
        ID.logout()
    },

    isAuthenticated () {
        return store.getters.accessToken
    },

    switchRegion () {
        ID.switchRegion({
            region: environ.getRegionForIdServer(),
            env: environ.getEnvironment()
        })
    }
}
