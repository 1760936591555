import { dpExecutor } from './http'
import endpointsHybrid from '../enums/endpoints-hybrid'
import { buildPath } from '../utils/url-helper'

export function createDPApiClient() {
    return new DataPlatformClientApi(endpointsHybrid)
}

const DataPlatformClientApi = class {
    constructor(endpoints) {
        this.endpoints = endpoints
    }

    getUsageAnalytics(organizationId) {
        const endpoint = buildPath(this.endpoints.CSV_ORGANIZATION, {
            internalOrganizationId: organizationId,
            internalOrganizationId2: organizationId
        })
        return dpExecutor.get(endpoint)
    }
}
