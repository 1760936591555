import {AppStates, DialogStates} from '../enums'
import {MENU_STATE} from '../enums/subscription-menu-state.js'
import {MANAGEMENT} from '../enums/admin-management-constants'

export function defaultState () {
    return {
        accessToken: '',
        appState: AppStates.MOUNTED,
        dialogState: DialogStates.NONE,
        ui: {
            pageTitle: '',
            selectedOrganizationId: null,
            selectedOrganizationName: null,
            analyticsOrgIdForRedirect: null
        },
        clientConnection: {
            windowReference: null,
            pullingIntervalReference: null
        },

        user: {
            profile: {},
            error: false
        },

        userSettings: {
            showCsvInstructions: false,
            seenSaasyComingSoonNotification: false,
            seenSaasyReleaseNotification: false,
            manageUsersOnboardingStep: 1,
            subscriptionExpiryConfirmedDate: ''
        },

        notifications: [],

        organizations: {
            recent: [],
            direct: [],
            related: [],
            tracking: {
                directMap: {},
                relatedMap: {},
                directNameTracker: {}
            },
            // Management object to keep track of permissions per organization for signed in user
            permissionsByOrg: {},
            filtered: [],
            filterByName: ''
        },

        districtLibraries: {},

        subscriptions: {},

        subscriptionUsers: {
            loadingFlag: false,
            subscriptionId: '',
            users: []
        },

        confirmedBrowserResizeWarning: false,

        outageStatus: {
            showAlert: false,
            outageType: null
        },
        subscriptionMenu: {
            selectedMenuOption: MENU_STATE.SUBSCRIPTION
        },
        managementMenu: {
            selectedMenuOption: MANAGEMENT.org
        },
        organizationAdmins: {
            loadingFlag: false,
            orgId: '',
            filterByName: '',
            filterByRole: '',
            allAdmins: {},
            adminsForOrg: {},
            selected: [],
            filtered: [],
            adminCountForOrg: {}
        },
        activation: {
            enable: false,
            CurrentComponentValidated: false,
            redeem: {
                code: '',
                codeValidated: false
            },
            claim: {
                serialNumber: '',
                purchasedBoardCount: '',
                updated: false,
                claimSerialNumber: true
            },
            productList: [],
            organizationList: [],
            selectedOrganizationId: '',
            selectedOrganizationName: '',
            LicenceKey: '',
            deferredActivationCode: [],
            newEntitlementClaimSelected: true,
            processSteps: 1,
            userAccount: {
                id: '',
                name: '',
                address1: '',
                address2: '',
                address3: '',
                city: '',
                country: '',
                industry: '',
                state: '',
                zipPostal: '',
                isIndividual: 'false',
                phone: '',
                region: '',
                dataLocation: ''
            },
            reseller: {
                name: '',
                city: ''
            },
            admins: {
                previouslyLoaded: false,
                showAllAdminsRemovedMessage: false,
                errorCount: 0,
                adminList: []
            }
        },
        onboarding: {
            // TODO - review if we should create an object for different possible onboardings in AP
            // User Story 233561: As a dev, I have implemented the full manage users onboarding flow
            totalSteps: 4,
            hideOnboarding: false,
            isActiveFlow: false
        },
        isModalOpen: false
    }
}
