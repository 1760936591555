<template>
    <div class='org-management-table'>
        <div class="no-overflow-table-container">
            <v-data-table id="orgTable"
                          ref="orgTable"
                          :items="userOrgs"
                          :headers="headers"
                          :headers-length="headers.length"
                          :options.sync="options"
                          :sort-by.sync="sortOptions.sortBy"
                          :sort-desc.sync="sortOptions.sortDesc"
                          :no-data-text="$t('management.orgTable.noMatches')"
                          hide-default-header
                          hide-default-footer
                          class="fixed-headers"
                          loader-height=3
                          loading-text="">
                <template v-slot:header="{ props: { headers } }">
                     <thead class="v-data-table-header">
                         <tr>
                            <th v-for="header in headers"
                                :key="header.text"
                                :class="['column',
                                    sortOptions.sortDesc ? 'desc' : 'asc',
                                    header.value === sortOptions.sortBy ? 'active' : '',
                                    header.sortable ? 'enable-sort' : 'disable-sort'
                                    ]"
                                    :tabindex="header.sortable ? 0 : -1"
                                    @click="header.sortable ? changeSort(header.value) : null"
                                    >
                                    <div class="header-text">
                                        {{ header.text }}
                                    </div>
                                    <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item="props">
                    <tr class="prop-wrapper">
                        <td>
                            <div v-if="hasAdminPermission(props.item.id) || isOnlyDslEnabled">
                                <a class="font-weight-bold"
                                @click="updateOrgInformation(props.item)">
                                    {{props.item.name}}
                                </a>
                            </div>
                            <div v-else>
                                <span class="font-weight-bold">
                                    {{props.item.name}}
                                </span>
                            </div>
                        </td>
                        <td>{{ regionOfItem(props.item) }}</td>
                        <td>{{ createLocationInformationString(props.item) }}</td>
                        <td align="left">
                            <v-icon v-if="isOnlyDslEnabled" class="icon" @click="updateOrgInformation(props.item)">settings</v-icon>
                            <v-icon class="icon" @click="pushToManageAdmin(props.item)">group</v-icon>
                            <template v-if="isShowUsageAnalyticsEnabled">
                                <v-icon v-if="isOrgAdmin(props.item.id)" @click="downloadAnalytics(props.item.id)" class="icon">analytics</v-icon>
                                <item-with-tooltip v-else :tooltip-text="$t('usageAnalytics.tooltips.adminAccess')" left-aligned>
                                    <template v-slot:item>
                                        <v-icon disabled class="icon">analytics</v-icon>
                                    </template>
                                </item-with-tooltip>
                            </template>
                        </td>
                    </tr>
                </template>
                <template v-slot:footer>
                    <div :colspan="headers.length" v-if="userOrgs.length > 0" class="footer position-relative">
                        <div class="text-center pt-2 pagination-position">
                            <v-pagination v-model="options.page" :length="pages" :total-visible="9" @input="updatePage()"/>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>

    import {MANAGEMENT, PERMISSIONS, ADMIN} from '../../enums/admin-management-constants'
    import {bus} from '../../event-bus'
    import {changeSortByOrder, scrollToTopOfTable} from '../../utils/pagination-utils'
    import PermissionValidator from '../../utils/permission-validator'
    import {DEFAULT, EU1} from '../../enums/regions'
    import {ONLY_DSL, SHOW_USAGE_ANALYTICS} from '../../enums/feature-switch-constants'
    import {isEnabled} from '../../services/feature-switch'
    import {mapGetters} from 'vuex'
    import ItemWithTooltip from '../ItemWithTooltip.vue'
    const ORGS_PER_PAGE = 15

    export default {
        name: 'OrganizationTable',
        components: {
            ItemWithTooltip
        },
        data () {
            return {
                nameHeader: {text: this.$t('management.orgTable.name'), value: 'name', align: 'left', sortable: true},
                regionHeader: {text: this.$t('management.orgTable.region'), value: 'region', align: 'left', sortable: true},
                addressHeader: {text: this.$t('management.orgTable.address'), value: 'address', align: 'left', sortable: true},
                options: {
                    itemsPerPage: ORGS_PER_PAGE,
                    page: 1
                },
                sortOptions: {
                    sortBy: 'name',
                    sortDesc: false
                },
                pageChanged: false,
                searchUpdated: false
            }
        },
        mounted () {
            this.pageChanged = true
        },
        updated () {
            if ((this.pageChanged || this.searchUpdated) && this.displayedOrgs.length) {
                this.pageChanged = false
                this.searchUpdated = false
            }
        },
        computed: {
            ...mapGetters([
                'getOrganizations'
            ]),
            isOnlyDslEnabled () {
                return !!isEnabled(ONLY_DSL)
            },
            isShowUsageAnalyticsEnabled() {
                return isEnabled(SHOW_USAGE_ANALYTICS)
            },
            // TODO moving adminHeader to computed property to have access to FS value, it can be moved back in data once we get ride of
            // the use of the fe-only-dsl FS, also adding a logic to hide the admininistrators column header text if the FS is enabled
            // that logic need to be removed when we get ride of the FS too
            adminHeader() {
                return {
                    ...(!this.isOnlyDslEnabled && {text: this.$t('management.orgTable.administrators')}), value: 'administrators', sortable: false
                }
            },
            userOrgs () {
                return this.$store.getters.getOrganizationsForManagement
            },
            pages () {
                return Math.ceil(this.userOrgs.length / this.options.itemsPerPage)
            },
            displayedOrgs () {
                return this.$refs.orgTable.items.map(org => org.id)
            },
            searchOrgByName () {
                return this.$store.getters.getCurrentSearchOrgByNameValue
            },
            headers () {
                return [this.nameHeader, this.regionHeader, this.addressHeader, this.adminHeader]
            }
        },
        watch: {
            'searchOrgByName' () {
                this.updatePageIndex()
                this.searchUpdated = true
            }
        },
        methods: {
            downloadAnalytics(orgId) {
                this.$emit('redirect-to-analytics', orgId)
            },
            isOrgAdmin(orgId) {
                const role = this.getOrganizations.find(org => org.id === orgId).role.name
                return role === ADMIN
            },
            regionOfItem(data) {
                const region = data.region

                switch (region) {
                case DEFAULT:
                    return this.$t('region.default.name')
                case EU1:
                    return this.$t('region.eu1.name')
                default:
                    return '-'
                }
            },
            createLocationInformationString (data) {
                let locationInfo = [data.address, data.city, data.state, data.country, data.zipPostal]
                let locationString = ''
                for (let i = 0; i < locationInfo.length; i++) {
                    if (locationInfo[i]) {
                        locationString = locationString + locationInfo[i] + ', '
                    }
                }
                return locationString.slice(0, -2)
            },
            pushToManageAdmin (data) {
                this.$store.commit('setManagementOptionToViewAdminForOrg',
                    { selectedMenuOption: MANAGEMENT.admin, orgId: data.id})
            },
            changeSort (column) {
                changeSortByOrder(this.sortOptions, column)
            },
            updatePage () {
                scrollToTopOfTable('orgTable')
                this.pageChanged = true
            },
            updatePageIndex () {
                this.options.page = 1
            },
            updateOrgInformation (org) {
                if (this.isOnlyDslEnabled) {
                    this.$emit('open-org-update-dialog-new', org)
                } else {
                    bus.$emit('openOrgUpdateDialog', org)
                }
            },
            hasAdminPermission (orgId) {
                return PermissionValidator.validateUserPermission(orgId, PERMISSIONS.ManageOrgs)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .scrollable-activation {
        overflow: auto;
    }

    .icon {
        color: #159bd9 !important;
        margin-right: 20px;
    }
</style>
<style lang="scss">
    @import "../../theming/fixed-headers-table.scss";

    .tbody-no-content .v-data-table__wrapper > * > tbody {
        height: 0 !important;
        width: 100%;
    }

    tbody tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, .05);
    }

    .prop-wrapper {
        display: block;
        width: 100%;
    }

    .org-management-table {
        @include table-fixed-headers(
            $containerHeight: 100vh,
            $heightOffset: 235px,
            $containerWidth: 100vw,
            $widthOffset: 200px,
            $headerHeight: 56px,
            $numCols: 4,
            $colRatios: 1.25 0.5 1.5 0.75
        )
    }

</style>
