import { render, staticRenderFns } from "./OrganizationTable.vue?vue&type=template&id=a99acde4&scoped=true"
import script from "./OrganizationTable.vue?vue&type=script&lang=js"
export * from "./OrganizationTable.vue?vue&type=script&lang=js"
import style0 from "./OrganizationTable.vue?vue&type=style&index=0&id=a99acde4&prod&lang=scss&scoped=true"
import style1 from "./OrganizationTable.vue?vue&type=style&index=1&id=a99acde4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a99acde4",
  null
  
)

export default component.exports