import ErrorHandler from '../utils/error-handler'
import { idAuth } from './identity-auth'
import environ from 'smarttech-identity-environment'
import { ErrorCodes } from '../enums/'
import { store } from '../store/store'
import Q from 'q'

const validateAccessToken = function () {
    if (!idAuth.isAuthenticated()) {
        let authErr = new Error()
        authErr.errorGroup = ErrorHandler.emptyAccessToken
        throw authErr
    }
}

const getDefaultConfig = function (method) {
    return {
        method: method,
        compress: true,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.getters.accessToken
        }
    }
}

const verifySuccess = function (response) {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        if (response.status === 503) {
            document.getElementById('service-unavailable').classList.remove('hidden')
        }
        let error = new Error(response.statusText)
        error.response = response
        throw error
    }
}

const parseResponse = function (response) {
    let parsedResponse = {
        status: response.status
    }

    if (response.headers && response.headers.has('x-smarttech-next-batch-key')) {
        parsedResponse.nextToken = response.headers.get('x-smarttech-next-batch-key')
    }

    return response.text()
        .then(function (text) {
            if (text) {
                parsedResponse.json = JSON.parse(text)
            }

            return parsedResponse
        })
        .catch(function () {
            throw new Error(response.statusText)
        })
}

const appendTimestamp = function (endpoint) {
    // cache buster to prevent aggressive caching on get requests (especially IE)
    // we append the unique timestamp to api calls
    let joiner = endpoint.indexOf('?') > -1 ? '&' : '?'
    return endpoint + joiner + 't=' + new Date().getTime()
}

export const executeRequest = (endpoint, config, disallowRetry) => {
    let ep = environ.getSWPServiceUrl() + endpoint
    let deferred = Q.defer()

    fetch(appendTimestamp(ep), config)
        .then(verifySuccess)
        .then(parseResponse)
        .then(deferred.resolve)
        .catch(function (error) {
            let errorResponse = {
                statusCode: 500,
                message: '',
                traceId: '',
                errorCode: ErrorCodes.INTERNAL_ERROR
            }

            if (!error.response) {
                deferred.reject(errorResponse)
                return
            }

            parseResponse(error.response)
                .then(res => {
                    if (disallowRetry || !res.json || !res.json.error || !res.json.error.shouldRetry) {
                        if (res.json && res.json.error) {
                            deferred.reject(res.json.error)
                        } else {
                            errorResponse.statusCode = res.statusCode
                            deferred.reject(errorResponse)
                        }
                    } else {
                        // if we're allowed to retry, retry the same request
                        executeRequest(endpoint, config, true)
                            .then(deferred.resolve)
                            .catch(deferred.reject)
                    }
                }).catch(() => {
                    deferred.reject(errorResponse)
                })
        })

    return deferred.promise
}

const executeDataPlatformRequest = async (endpoint, config) => {
    const ep = environ.getDpServiceUrl() + endpoint
    const response = await fetch(ep, config)
    if (!response.ok) {
        let errorResponse
        switch (response.status) {
        case 401:
            errorResponse = { errorCode: ErrorCodes.DP_UNAUTHORIZED }
            break
        case 404:
            errorResponse = { errorCode: ErrorCodes.DP_NO_DATA_AVAILABLE }
            break
        default:
            errorResponse = { errorCode: ErrorCodes.INTERNAL_ERROR }
        }
        throw errorResponse
    }
    return response
}

export const executePost = function (endpoint, body, accessTokenRequired = true) {
    return swpExecutor.post(endpoint, body, accessTokenRequired)
}

export const executePut = function (endpoint, body, accessTokenRequired = true) {
    return swpExecutor.put(endpoint, body, accessTokenRequired)
}

export const executeGet = function (endpoint, accessTokenRequired = true) {
    return swpExecutor.get(endpoint, accessTokenRequired)
}

export const executeDelete = function (endpoint, body, accessTokenRequired = true) {
    return swpExecutor.delete(endpoint, body, accessTokenRequired)
}

const createServiceExecutor = (serviceConfig) => ({
    get: (endpoint, accessTokenRequired = true) => {
        if (accessTokenRequired) {
            validateAccessToken()
        }
        const config = getDefaultConfig('GET')
        return serviceConfig.executeFn(endpoint, config)
    },
    post: (endpoint, body, accessTokenRequired = true) => {
        if (accessTokenRequired) {
            validateAccessToken()
        }
        const config = getDefaultConfig('POST')
        config.body = JSON.stringify(body)
        return serviceConfig.executeFn(endpoint, config)
    },
    put: (endpoint, body, accessTokenRequired = true) => {
        if (accessTokenRequired) {
            validateAccessToken()
        }
        const config = getDefaultConfig('PUT')
        config.body = JSON.stringify(body)
        return serviceConfig.executeFn(endpoint, config)
    },
    delete: (endpoint, body, accessTokenRequired = true) => {
        if (accessTokenRequired) {
            validateAccessToken()
        }
        const config = getDefaultConfig('DELETE')
        if (body) {
            config.body = JSON.stringify(body)
        }
        return serviceConfig.executeFn(endpoint, config)
    }
})

export const dpExecutor = createServiceExecutor({ executeFn: executeDataPlatformRequest })

export const swpExecutor = createServiceExecutor({ executeFn: executeRequest })
