// IMPORTANT
// update codes in
// Confluence: https://smarttech.atlassian.net/wiki/spaces/ID/pages/4618493/Software+Portal+Error+Codes
// googleClassroom: /packages/google-classroom-web/src/util/error-constants.js
// Java: com.smarttech.swp.subscription.service.exception.ErrorCode

export const ERROR_CSV_FILE_UPLOAD_CODE = 'SP-2000'
export const INTERNAL_ERROR = 'SP-1000'
export const BAD_REQUEST_ERROR = 'SP-0003'
export const INVALID_TENANT_ID = 'SP-0200'
export const SYNC_INPROGRESS = 'SP-0201'
export const SUBSCRIPTION_NOT_FOUND = 'SP-0202'
export const SISSERVER_NOT_FOUND = 'SP-0203'
export const SOURCE_UPDATE_ERROR = 'SP-0204'
export const ORGANIZATION_NOT_FOUND = 'SP-0205'
export const DELETE_LAST_ADMIN_ERROR = 'SP-0113'
export const DP_UNAUTHORIZED = 'SP-3000'
export const DP_NO_DATA_AVAILABLE = 'SP-3001'
export const FORBIDDEN = 403
