<template>
    <v-layout justify-center align-center>
        <v-card tile class="pb-2 pt-2 px-12" max-width="470" elevation="2">
            <v-card-title class="text-h4 px-0 pb-3 font-weight-bold text--primary">
                {{ $t('usageAnalytics.lumioAnalytics') }}
                <v-layout justify-end align-end><v-icon>help_outline</v-icon></v-layout>
            </v-card-title>
            <v-flex class="mb-1">{{ $t('usageAnalytics.description') }}</v-flex>
            <v-flex class="mb-1">{{ $t('usageAnalytics.organization') }}</v-flex>
            <v-select
                v-model="selectedOrgId"
                :items="organizations"
                item-value="id"
                item-text="name"
                class="mb-n2"
                single-line
                solo flat
                :height=30>
            </v-select>
            <v-card-actions class="justify-end pr-0 pt-0 pb-3">
                <v-btn
                    min-width="125" height="30"
                    class="primary light white--text"
                    :loading="loading"
                    @click.native="triggerDownload">
                    {{ $t('usageAnalytics.exportData') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { createDPApiClient } from '../services/data-platform-client'
import ResizeMixin from '../mixins/resize-mixin'
import ErrorHandler from '../utils/error-handler'
import { ADMIN } from '../enums/admin-management-constants'
import LicenseRequestService from '../services/license-request-service'
import SubscriptionUpdater from '../utils/subscription-updater'
import { TIER_TYPES } from '../enums/subscription-constants'

const DOWNLOAD_FILENAME = 'analytics'

export default {
    mixins: [ResizeMixin],
    data () {
        return {
            selectedOrgId: null,
            organizations: [],
            loading: false
        }
    },
    mounted() {
        this.checkDirectOrgCount(this.getDirectOrganizationsCount)
        this.organizations = this.getOrganizations.filter(org => org.role.name === ADMIN)
        this.selectedOrgId = this.$store.state.ui.analyticsOrgIdForRedirect || this.organizations[0].id
        this.$store.commit('setAnalyticsOrgIdForRedirect', null)
    },
    computed: {
        ...mapGetters([
            'getOrganizations',
            'subscriptionsByOrganization',
            'getSubscriptionById',
            'getDirectOrganizationsCount'
        ])
    },
    watch: {
        'directOrganizationsCount' (newCount) {
            this.checkDirectOrgCount(newCount)
        }
    },
    methods: {
        ...mapActions([
            'FetchSLSSubscription',
            'getUsageAnalytics',
            'FetchSubscriptionsForOrganizationHybrid'
        ]),
        ...mapMutations([
            'ClearSubscriptionsForOrganization'
        ]),
        async triggerDownload() {
            this.loading = true
            try {
                const internalOrganizationId = await this.getInternalOrganizationId()
                const apiClient = createDPApiClient()
                const response = await apiClient.getUsageAnalytics(internalOrganizationId)

                const blob = await response.blob()
                this.downloadBlobAsLink(blob)
            } catch (error) {
                const errorGroup = ErrorHandler.usageAnalyticsError
                ErrorHandler.handleError(error, errorGroup)
            } finally {
                this.loading = false
            }
        },

        downloadBlobAsLink(blob) {
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            const currentDate = new Date().toISOString().split('T')[0]
            link.href = url
            link.download = `${this.organizations.find(org => org.id === this.selectedOrgId).name}_${DOWNLOAD_FILENAME}_${currentDate}.csv`
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(url)
            link.remove()
        },

        async getInternalOrganizationId() {
            let orgSubscription = this.subscriptionsByOrganization(this.selectedOrgId).find(org => !org.isLegacy)
            if (!orgSubscription) {
                await this.fetchSubscriptionsForOrg(this.selectedOrgId)
                orgSubscription = this.subscriptionsByOrganization(this.selectedOrgId).find(org => !org.isLegacy)
            }
            const firstSubscription = this.getSubscriptionById(orgSubscription.id)
            if (!firstSubscription.internalOrganizationId) {
                await this.FetchSLSSubscription({
                    organizationId: this.selectedOrgId,
                    subscriptionId: orgSubscription.id,
                    tierType: firstSubscription.relevantTier || TIER_TYPES.BASE
                })
            }

            return this.getSubscriptionById(orgSubscription.id)?.internalOrganizationId || null
        },
        checkDirectOrgCount (orgCount) {
            if (orgCount === 0) {
                this.$router.push('/products')
            }
        },
        async fetchSubscriptionsForOrg(organizationId) {
            return new Promise((resolve, reject) => {
                const respListener = (updateInfo, isLoadingCompleted) => {
                    if (updateInfo.error) {
                        LicenseRequestService._cancelRequests()
                        ErrorHandler.handleError(updateInfo.data)
                        reject(updateInfo.data)
                    } else {
                        SubscriptionUpdater.updateSubscription(updateInfo, this.$store)
                        if (isLoadingCompleted) {
                            resolve()
                        }
                    }
                }
                const expireListener = (orgId) => {
                    this.ClearSubscriptionsForOrganization(orgId)
                }
                this.FetchSubscriptionsForOrganizationHybrid({ organizationId, respListener, expireListener })
                    .catch(reject)
            })
        }
    }
}
</script>
