<template>
    <div class="management-route scrollable-activation">
        <v-container fluid>
            <v-overlay :value="loadingOrgData">
                <v-progress-circular
                  :size="50"
                  :width="5"
                  class="primary--text progress"
                  indeterminate>
                </v-progress-circular>
            </v-overlay>
            <management-dropdown class="management-toolbar"></management-dropdown>
            <admin-table v-if="selectedMenuOption === adminTable"></admin-table>
            <organization-table v-else
                @open-org-update-dialog-new="openOrgUpdateDialogNew"
                @redirect-to-analytics="onRedirectToAnalytics">
            </organization-table>
        </v-container>
        <admin-info-dialog></admin-info-dialog>
        <org-update-dialog></org-update-dialog>
        <org-update-dialog-new
            v-if="orgUpdateDialogNewOpen"
            :org="selectedOrg"
            @close-dialog="closeDialog">
        </org-update-dialog-new>
    </div>
</template>

<script>
    import ManagementDropdown from '../components/Management/ManagementDropdown'
    import AdminInfoDialog from '../components/Management/AdminInfoDialog'
    import AdminTable from '../components/Management/AdminTable'
    import OrganizationTable from '../components/Management/OrganizationTable'
    import OrgUpdateDialog from '../components/Management/OrgUpdateDialog'
    import OrgUpdateDialogNew from '../components/Management/OrgUpdateDialogNew'
    import ResizeMixin from '../mixins/resize-mixin'
    import { MANAGEMENT, ADMIN, TECH_INSTRUCTOR } from '../enums/admin-management-constants'
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import ErrorHandler from '../utils/error-handler'
    import LicenseRequestService from '../services/license-request-service'
    import SubscriptionUpdater from '../utils/subscription-updater'

    export default {
        name: 'Management',
        components: {
            AdminInfoDialog,
            AdminTable,
            ManagementDropdown,
            OrganizationTable,
            OrgUpdateDialog,
            OrgUpdateDialogNew
        },
        mixins: [ResizeMixin],
        data () {
            return {
                orgUpdateDialogNewOpen: false,
                selectedOrg: null,
                loadingOrgData: false
            }
        },
        computed: {
            ...mapGetters([
                'orgSparkSubscription',
                'selectedOrganizationId'
            ]),
            adminTable () {
                return MANAGEMENT.admin
            },
            orgTable () {
                return MANAGEMENT.org
            },
            selectedMenuOption () {
                return this.$store.getters.getManagementSelectedMenuOption
            },
            directOrganizationsCount () {
                return this.$store.getters.getDirectOrganizationsCount
            }
        },
        watch: {
            'directOrganizationsCount' (newCount) {
                this.checkDirectOrgCount(newCount)
            }
        },
        methods: {
            ...mapMutations([
                'ClearSubscriptionsForOrganization'
            ]),
            ...mapActions([
                'loadDLMetadata',
                'FetchSLSSubscription',
                'FetchSubscriptionsForOrganizationHybrid'
            ]),
            checkDirectOrgCount (orgCount) {
                if (orgCount === 0) {
                    this.$router.push('/products')
                }
            },
            async getSettingsTabDataForSparkOrg(org) {
                let orgSparkSubscription
                orgSparkSubscription = this.orgSparkSubscription(org.id)
                // When we are above the VIEW_ALL_ORG_THRESHOLD, meaning we have more than 12 organizations, only one organization
                // is selected and shown in the Subscriptions page and, unless the user selects other orgs, their subscription data is not loaded therefore
                // we might not have information about the org having a spark subscription at this point.
                // The below logic takes care of such cases.
                if (!orgSparkSubscription && this.selectedOrganizationId !== org.id) {
                    await this.fetchSubscriptionsForOrg({organizationId: org.id})
                    orgSparkSubscription = this.orgSparkSubscription(org.id)
                }
                // Now we can load the data needed for the 2 tabs dialog for the selected org
                if (orgSparkSubscription) {
                    const {id, relevantTier} = orgSparkSubscription
                    const res = await this.FetchSLSSubscription({organizationId: org.id, subscriptionId: id, tierType: relevantTier})
                    const {extOrganizationId, extSubscriptionId, organizationId, subscriptionId} = res
                    await this.loadDLMetadata({extOrgId: extOrganizationId, extSubId: extSubscriptionId, orgId: organizationId, subId: subscriptionId})
                }
            },
            async openOrgUpdateDialogNew(org) {
                try {
                    const showTabs = org.role.name === ADMIN || org.role.name === TECH_INSTRUCTOR
                    if (showTabs) {
                        this.loadingOrgData = true
                        await this.getSettingsTabDataForSparkOrg(org)
                        this.loadingOrgData = false
                    }
                    this.openDialog(org)
                } catch (error) {
                    this.loadingOrgData = false
                    ErrorHandler.handleError(error, ErrorHandler.dialogueError)
                }
            },
            async fetchSubscriptionsForOrg({ organizationId }) {
                return new Promise((resolve, reject) => {
                    const respListener = (updateInfo, isLoadingCompleted) => {
                        if (updateInfo.error) {
                            LicenseRequestService._cancelRequests()
                            ErrorHandler.handleError(updateInfo.data)
                            reject(updateInfo.data)
                        } else {
                            SubscriptionUpdater.updateSubscription(updateInfo, this.$store)
                            if (isLoadingCompleted) {
                                resolve()
                            }
                        }
                    }
                    const expireListener = (orgId) => {
                        this.ClearSubscriptionsForOrganization(orgId)
                    }
                    this.FetchSubscriptionsForOrganizationHybrid({ organizationId, respListener, expireListener })
                        .then(() => {
                            resolve()
                        })
                        .catch(reject)
                })
            },
            onRedirectToAnalytics(orgId) {
                this.$store.commit('setAnalyticsOrgIdForRedirect', orgId)
                this.$router.push('/analytics')
            },
            openDialog(org) {
                this.selectedOrg = org
                this.orgUpdateDialogNewOpen = true
            },
            closeDialog() {
                this.$store.commit('setIsModalOpen', false)
                this.orgUpdateDialogNewOpen = false
                this.selectedOrg = null
            }
        },
        mounted () {
            this.checkDirectOrgCount(this.directOrganizationsCount)
        }
    }
</script>

<style lang="scss" scoped>
    .scrollable-activation {
        overflow: auto;
    }

    .management-toolbar {
        height: 70px;
    }

</style>
