<template>
    <v-layout wrap mb-5 class="software-menu-container">
        <v-flex xs8>
            <div class="top-menu">
                <div class="software-menu-option manage"
                        v-on:click="selectedMenuOption = MENU_STATE.SUBSCRIPTION"
                        v-on:keydown.enter="selectedMenuOption = MENU_STATE.SUBSCRIPTION"
                        tabindex="0">
                    <div :class="{'selected-menu-option': selectedMenuOption === MENU_STATE.SUBSCRIPTION}"
                         class="software-menu-option-fit">
                        {{$t('subscriptions.menu.manage')}}
                    </div>
                </div>
                <div class="software-menu-option"
                        v-on:click="selectedMenuOption = MENU_STATE.ACTIVATION"
                        v-on:keydown.enter="selectedMenuOption = MENU_STATE.ACTIVATION"
                        tabindex="0">
                    <div :class="{'selected-menu-option': selectedMenuOption === MENU_STATE.ACTIVATION}"
                         class="software-menu-option-fit">
                        {{ softwareMenuOptionText }}
                    </div>
                </div>
            </div>
        </v-flex>
        <v-layout justify-end>
            <v-flex class="org-dropdown">
                <v-autocomplete v-bind:items='orgList'
                          v-if="selectedMenuOption === MENU_STATE.SUBSCRIPTION"
                          v-on:input='onInput'
                          :menu-props="{maxHeight:'calc(100vh - 300px)', bottom:''}"
                          :value='organization'
                          item-text='name'
                          item-value='value'
                          :placeholder='$t("orgDropdown.placeholder")'
                          flat
                          solo
                          single-line
                          hide-details
                          :filter="filterOrgs">
                    <template v-slot:item="data">
                        <v-list-item-content class='dropDownRecent'>
                            <v-list-item-title>
                                <span :class='data.item.type'>{{data.item.name}}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-flex>
        </v-layout>
    </v-layout>
</template>
<script>
    import {mapState} from 'vuex'
    import {MENU_STATE} from '../enums/subscription-menu-state.js'
    import {orgSort} from '../utils/organization-sorting-util'
    import ErrorHandler from '../utils/error-handler'
    import { isEnabled } from '../services/feature-switch'
    import { FUSE_API, HYBRID_VIEW } from '../enums/feature-switch-constants'

    let orgSortByName = function (a, b) {
        return orgSort(a.name, b.name)
    }

    export default {
        name: 'SubscriptionsHeader',
        computed: {
            ...mapState([
                'user',
                'organizations'
            ]),
            orgList () {
                return this.buildOrganizationDropdown()
            },
            orgCount () {
                return this.organizationsList.length
            },
            organization () {
                if (this.organizationsList.length > this.$UI_CONFIG.SHOW_RECENT_THRESHOLD) {
                    return this.$UI_CONFIG.RECENT_PREFIX + this.$store.getters.selectedOrganizationId
                }

                return this.$store.getters.selectedOrganizationId
            },
            organizationsList () {
                return this.$store.getters.getOrganizations
            },
            selectedMenuOption: {
                get () {
                    return this.$store.getters.getSubscriptionSelectedMenuOption
                },
                set (value) {
                    this.$store.commit('setSubscriptionSelectedMenuOption', value)
                }
            },
            MENU_STATE () {
                return MENU_STATE
            },
            showHybridView() {
                return isEnabled(HYBRID_VIEW) || isEnabled(FUSE_API)
            },
            softwareMenuOptionText() {
                return this.showHybridView ? this.$t('subscriptions.menu.activateSoftware') : this.$t('subscriptions.menu.getYourSoftware')
            }
        },
        methods: {
            onInput: function (value) {
                let org = this.orgList.filter(function (v) {
                    return v.value === value
                })[0]

                if (org && org.name) {
                    this.$store.dispatch('SelectedOrganizationUpdated', {
                        name: org.name,
                        id: org.value.replace(this.$UI_CONFIG.RECENT_PREFIX, ''),
                        isDirect: org.isDirect
                    }).catch(err => {
                        ErrorHandler.handleError(err)
                    })
                }
            },
            filterOrgs (record, selectInputString) {
                if (!selectInputString || !record.name) return true
                return record.name.toLocaleLowerCase().indexOf(selectInputString.toLocaleLowerCase()) >= 0
            },
            buildOrganizationDropdown: function () {
                let orgList = []

                const orgTypes = {
                    ALL: 'all',
                    RECENT: 'recent'
                }

                let recentThresholdHit = this.organizationsList.length > this.$UI_CONFIG.SHOW_RECENT_THRESHOLD
                let recentExists = this.organizations.recent && this.organizations.recent.length

                if (recentThresholdHit && recentExists) {
                    orgList.push({
                        header: this.$t('orgDropdown.recent'),
                        value: 'recentHeader'
                    })

                    for (let i = 0; i < this.organizations.recent.length; i++) {
                        orgList.push({
                            name: this.organizations.recent[i].name,
                            value: this.$UI_CONFIG.RECENT_PREFIX + this.organizations.recent[i].id,
                            type: orgTypes.RECENT,
                            isDirect: this.organizations.recent[i].isDirect
                        })
                    }

                    orgList.push({
                        header: this.$t('orgDropdown.all'),
                        value: 'allHeader'
                    })
                }

                if (this.organizationsList.length <= this.$UI_CONFIG.VIEW_ALL_ORG_THRESHOLD) {
                    orgList.push({
                        name: this.$t('orgDropdown.allOrganizations'),
                        value: '',
                        type: orgTypes.ALL
                    })
                }

                let sorted = this.organizationsList.sort(orgSortByName)
                for (let i = 0; i < sorted.length; i++) {
                    orgList.push({
                        name: sorted[i].name,
                        value: sorted[i].id,
                        type: orgTypes.ALL,
                        isDirect: sorted[i].isDirect
                    })
                }

                return orgList
            }
        }
    }
</script>

<style lang="scss" scoped>
.software-menu-container {
    height: 46px;
    flex: none;
}

.dropDownRecent {
    padding-left: 10px;
    width: 100px;
}

.org-dropdown {
    max-width: 345px !important;
    height: 25px !important;
}

.software-menu-option {
    height: 100%;
    font-size: 18px;
    color: rgba(76, 77, 77, 0.7);
    cursor: pointer;
}

.software-menu-option-fit:hover {
    border-bottom: #388bd1 solid 3px;
}

.software-menu-option-fit {
    display: inline-block;
    min-height: 35px;
}

.selected-menu-option {
    color: #4c4d4d;
    border-bottom: #3487c9 solid 4px !important;
}

.manage {
    margin-right: 4em;
}

.top-menu {
    display: flex;
}

</style>
